import * as axios from "../network/Network";

const config = require("../network/api.json");

class HomeService {
    static addRegistrationform(
        first_name,
        last_name,
        email,
        address,
        gender,
        home_country,
        home_university,
        pursuing_year,
        emergency_name,
        emergency_phone,
        emergency_email,
        emergency_address,
        courses,
        date_of_birth,
        nationality,
        program,
        profile_photo,
        passport_photo,
        id_photo
    ) {
        console.log(date_of_birth)
        var bodyFormData = new FormData()
        bodyFormData.append("first_name", first_name)
        bodyFormData.append("last_name", last_name)
        bodyFormData.append("email", email)
        bodyFormData.append("address", address)
        bodyFormData.append("gender", gender)
        bodyFormData.append("home_country", home_country)
        bodyFormData.append("date_of_birth", date_of_birth)
        bodyFormData.append("nationality", nationality)
        bodyFormData.append("program", program)
        bodyFormData.append("home_university", home_university)
        bodyFormData.append("pursuing_year", pursuing_year)
        bodyFormData.append("emergency_name", emergency_name)
        bodyFormData.append("emergency_phone", emergency_phone)
        bodyFormData.append("emergency_email", emergency_email)
        bodyFormData.append("emergency_address", emergency_address)
        bodyFormData.append("courses", JSON.stringify(courses))
        bodyFormData.append("profile_photo", profile_photo)
        bodyFormData.append("passport_photo", passport_photo)
        bodyFormData.append("id_photo", id_photo)

        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.addRegistrationform,
            data: bodyFormData,
        }
        );
        return Promise.resolve(result);
    }

    static addCourses(){
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.addCourseList,
        }
        );
        return Promise.resolve(result);
    }

}
export { HomeService };