import axios from "axios";
import getStore from "../store";
import { sessionExpire } from "../actions/UserAction";

// import RefreshTokenHandler from "@network/RefreshTokenHandler";
/**
 * Create an Axios Client with defaults
 */
export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const PUT = "PUT";
const store = getStore;

//DEVELOPMENT
// export const DEFAULT_BASEURL = process.env.REACT_APP_APIURL;
//PRODUCTION
//export const DEFAULT_BASEURL = "http://services.lendycook.com/api/v1/";
export const DEFAULT_BASEURL = process.env.REACT_APP_APIURL;


const appClient = axios.create({
  baseURL: DEFAULT_BASEURL,
  headers: {
    "Accept-Language": "en",
  },
});

appClient.interceptors.request.use(function (config) {
  if (store) {
    // store.dispatch();
    let reduxState = store.getState();

    if (reduxState.userData && reduxState?.userData) {
      // if (config.url == configFile.user.refresh_token)
      //     config.headers.authorization =
      //         reduxState.userData.userSession.token_type + " " + reduxState.userData.userSession.refresh_token;
      // else

      config.headers.authorization =
        reduxState.userData?.type + " " + reduxState.userData?.token;
    }
  }

  return config;
});
// const errorHandler=()=>{

// }
// Add a response interceptor
appClient.interceptors.response.use(
  function (response) {
    // console.log("response", response);
    // Do something with response data
    return response;
  },
  function (error) {
    // const navigate = useNavigate();

    if (error?.response?.status === 401) {
      store.dispatch(sessionExpire);
      // navigate("/");
      // dispatch(alertActions.error("Please login"));
      // let originalRequest = error.config;
      // return RefreshTokenHandler.handleExpiredToken(originalRequest);
    }
    return Promise.reject(error);
  }
);
/**
 * On Success Callback for an api
 * @param {} response
 */

const onSuccess = function (response) {
  // console.log("Request Successful!", response);
  return response;
};

/**
 * On Failure Callback on an Api
 * @param {} error
 */
const onError = function (error) {
  // console.log("Request Failed:", error);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.log("Status:", error.response.status);
    console.log("Data:", error.response.data);
    console.log("Headers:", error.response.headers);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.log("Error Message:", error.message);
  }

  return Promise.reject(error.response || error.message);
};
/**
 * Request Wrapper with base url set o _baseUrl.
 */
const appRequest = function (options) {
  return appClient(options).then(onSuccess).catch(onError);
};

/**
 * This was created for uploading files, to get progress update
 */
const appPost = function (url, data, config) {
  return appClient.post(url, data, config).then(onSuccess).catch(onError);
};

export { appRequest, appPost };
