import React from 'react'
import { Routes,Route  } from 'react-router-dom'; 
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Home from "./Home/Home"

function App() {
  return (
    <div>  
    <Header />
    <Routes>
      <Route exact path='/' element={< Home />}></Route>  
    </Routes>  
    <Footer />
    </div>
  )
}

export default App
