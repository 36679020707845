import { SAVE_USER_DATA, SESSION_EXPIRE, USER_LOGOUT } from "../actions/types";

export function UserReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SAVE_USER_DATA:
      return {
        ...state,

        user: payload.user,
        token: payload.token,
        type: payload.type,
        session_expired: false,
      };
    case SESSION_EXPIRE:
      return {
        ...state,
        session_expired: true,
      };
    case USER_LOGOUT:
      state = {};
      return {
        ...state,
      };

    default:
      return state;
  }
}
