import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeService } from '../Services/HomeServices'
import './Home.css'
import Moment from 'moment';
import moment from 'moment';
import { format } from 'date-fns'

const HomeHook = (props) => {
    const [limitFile, setLimitFile] = useState(true)
    const [validFile, setValidFile] = useState(true)
    const [img1,setImage1]=useState(null)
    const [pdf,setPdf]=useState('')
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
    const [img2,setImage2]=useState(null)
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState(null);
    const [img3,setImage3]=useState(null)
    const [imagePreviewUrl3, setImagePreviewUrl3] = useState(null);
    // const [img4,setImage4]=useState(null)
    // const [imagePreviewUrl4, setImagePreviewUrl4] = useState(null);
    const [selectedValue, setSelectedValue]=useState([])
    const [firstname,setFirstname]=useState("")
    const [lastname,setLastname]=useState("")
    const [email,setEmail]=useState("")
    const [gender,setGender]=useState("")
    const [date,setDate]=useState('')
    const [address,setAddress]=useState("")
    const [country,setCountry]=useState("")
    const [nationality,setNationality]=useState("")
    const [university,setUniversity]=useState("")
    const [pursuingYear,setPursuingYear]=useState("")
    const [program,setProgram]=useState("")
    const [emergencyname,setEmergencyname]=useState("")
    const [emergencyphone,setEmergencyphone]=useState("")
    const [emergencyemail,setEmergencyemail]=useState("")
    const [emergencyaddress,setEmergencyaddress]=useState("")
    const [course,setcourse]=useState("")
    const [submitted,setSubmitted]=useState(false)
    const [isOpened, setIsOpened] = useState(false);
    const [checkbox,setCheckbox]=useState(false)
    const [formsubmitted,setFormsubmitted]=useState(false)
    const [id,setid]=useState("")
    const [boxsubmit,setBoxsubmit]=useState(false)
    const [validemail,setValidemail]=useState(false)
    const [emailValid, setEmailValid] = useState(true);
    const [total,setTotal]=useState(0)
    const [popup,setPopup]=useState(false)

   const navigate = useNavigate();

  const popupClose = () => {
    setPopup(!popup);
    //navigate("https://summer.manipal.edu/",{ replace: true })
  }



    const handleChange=(e)=>{
        if(e.target.name==='firstname'){
            setFirstname(e.target.value)
        }
        if(e.target.name==='lastname'){
            setLastname(e.target.value)
        }
        if(e.target.name==='email'){
            setEmail(e.target.value)
            // let pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            let pattern=/\S+@\S+\.\S+/;
           if (email && !pattern.test(email)) {
            setEmailValid(false);
            }
          else {
            setEmailValid(true)
           } // Email Validation
        }
        if(e.target.name==='gender'){
            setGender(e.target.value)
        }
        if(e.target.name==='address'){
            setAddress(e.target.value)
        }
        if(e.target.name==='country'){
          setCountry(e.target.value)
        }
        if(e.target.name==='nationality'){
            setNationality(e.target.value)
        }
        if(e.target.name==='university'){
            setUniversity(e.target.value)
        }
        if(e.target.name==='pursuingYear'){
            setPursuingYear(e.target.value)
        }
        if(e.target.name==='program'){
            setProgram(e.target.value)
        }
        if(e.target.name==='emergencyname'){
          setEmergencyname(e.target.value)
        }
        if(e.target.name==='emergencyphone'){
            // setEmergencyphone(e.target.value)
            const result = e.target.value.replace(/\D/g, '');

            setEmergencyphone(result);
        }
        if(e.target.name==='emergencyemail'){
            setEmergencyemail(e.target.value)
            //let pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            let pattern=/\S+@\S+\.\S+/;
           if (emergencyemail && !pattern.test(emergencyemail)) {
            setValidemail(false);
            }
          else {
            setValidemail(true)
           } // Email Validation
        }
        if(e.target.name==='emergencyaddress'){
            setEmergencyaddress(e.target.value)
        }
    }

    // const handleDate=(date)=>{
    //     setDate( date)  
    // }
    const handleDate=(e)=>{
       //setDate(e.target.value)
       setDate(moment(e).format("YYYY-MM-DD"));
    }
    console.log(date)

    const fileHandler1=(e)=>{
        if (e.target.files.length) {
            setImagePreviewUrl1(null);
            if (e.target.files[0].size > 1000000 * 10) {
              setLimitFile(false);
              e.target.files = null;
              return false;
            }
            else {
              setImage1(e.target.files[0]);
              e.preventDefault();
              let reader = new FileReader();
              let file = e.target.files[0];
              console.log(file);
              if (file.type.match('image/png')  || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
                reader.onloadend = () => {
                  setImagePreviewUrl1(reader.result);
                  setValidFile(true)
                }
              }
              else {
                setValidFile(false)
              }
              reader.readAsDataURL(file);
            }
      
          }
    }

    const fileHandler2=(e)=>{
        if (e.target.files.length) {
            setImagePreviewUrl2(null);
            // setPdf('')
            if (e.target.files[0].size > 1000000 * 10) {
              setLimitFile(false);
              e.target.files = null;
              return false;
            }
            else {
              // setPdf(e.target.files[0])
              setImage2(e.target.files[0]);
              e.preventDefault();
              let reader = new FileReader();
              let file = e.target.files[0];
              console.log(file);
              if (file.type.match('image/png') || file.type.match('image/pdf') || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
                reader.onloadend = () => {
                  setImagePreviewUrl2(reader.result);
                  setValidFile(true)
                }
              }
              else {
                setValidFile(false)
              }
              reader.readAsDataURL(file);
            }
      
          }
    }

    const fileHandler3=(e)=>{
        if (e.target.files.length) {
            setImagePreviewUrl3(null);
            if (e.target.files[0].size > 1000000 * 10) {
              setLimitFile(false);
              e.target.files = null;
              return false;
            }
            else {
              setImage3(e.target.files[0]);
              e.preventDefault();
              let reader = new FileReader();
              let file = e.target.files[0];
              console.log(file);
              if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
                reader.onloadend = () => {
                  setImagePreviewUrl3(reader.result);
                  setValidFile(true)
                }
              }
              else {
                setValidFile(false)
              }
              reader.readAsDataURL(file);
            }
      
          }
    }

    // const fileHandler4=(e)=>{
    //     if (e.target.files.length) {
    //         setImagePreviewUrl4(null);
    //         if (e.target.files[0].size > 1000000 * 10) {
    //           setLimitFile(false);
    //           e.target.files = null;
    //           return false;
    //         }
    //         else {
    //           setImage4(e.target.files[0]);
    //           e.preventDefault();
    //           let reader = new FileReader();
    //           let file = e.target.files[0];
    //           console.log(file);
    //           if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
    //             reader.onloadend = () => {
    //               setImagePreviewUrl4(reader.result);
    //               setValidFile(true)
    //             }
    //           }
    //           else {
    //             setValidFile(false)
    //           }
    //           reader.readAsDataURL(file);
    //         }
      
    //       }
    // }

    const handleSelectChange = (e) => {
     setSelectedValue(Array.isArray(e) ? e.map(x => x.label) : []);   
     setid(Array.isArray(e) ? e.map(x => x.value) : [])

    //  if(e.target.name==="selects"){
    //   setSelectedValue(e.target.value)
    // }
    }


    const checkboxhandler=(e)=>{
        setSubmitted(true)
        //setBoxsubmit(true)
        setCheckbox(e.target.checked)
    }

    const getCourses=(e)=>{
        HomeService.addCourses()
        .then((data)=>{
            var arr=[];
            data.data.data.forEach(element => {
                arr.push({"value":element._id,"label":element.course_name})
               
            });
            setcourse(arr)
            //console.error(data.data.data)
        })
        .catch((error)=>{
            console.log("Error",error)
        })
    }

    // const array=[]
    // setSelectedValue(array)
    // const list1=array.map((x,i)=>(
    //   <li key={i}>{i+1}. {x}</li>
    // ))
    //const array=selectedValue

    const list=selectedValue.map((y,i)=>(
      <>
        <li key={i}>{i+1}. {y}
        {/* <p className='amount'>Fee €1000</p>  */}
         </li>
      </>
    ))

    // const totalAmount=(e)=>{
    //   setTotal(total * 1000)
    // }

    useEffect(()=>{
        getCourses()
        setTotal(total*3)
    },[total])

    const formsubmit=(e)=>{
      setIsOpened(false);
      setFormsubmitted(true)
      //setSelectedValue(true)
      if(firstname && lastname && email && emailValid && validemail && gender && selectedValue.length>0 && date && address && country && nationality && university && pursuingYear && program && emergencyname && emergencyphone && emergencyemail && emergencyaddress && img1 && img2 && img3){
         setFormsubmitted(false)
         setIsOpened(true)
         //setSelectedValue(true)
       }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        setSubmitted(true)
        setIsOpened(true)
        setCheckbox(false)
        setPopup(false)
        //setBoxsubmit(true)
        //const alertmsg=window.confirm("Are You sure you want to submit ?")
        // if(alertmsg && checkbox && firstname && lastname && email && gender && date && address && nationality && university && pursuingYear && program && emergencyphone && emergencyemail && emergencyaddress && img1 && img2 && img3 && img4){
          if( checkbox){
        HomeService.addRegistrationform(firstname,
            lastname,
            email,
            address,
            gender,
            country,
            university,
            pursuingYear,
            emergencyname,
            emergencyphone,
            emergencyemail,
            emergencyaddress,
            //selectedValue,
            id,
            date,
            nationality,
            program,            
            img1,
            img2,
            img3,
              )
            .then((data)=>{
                if(data.data.status.code===0){
                     setFirstname("")
                     setLastname("")
                     setEmail("")
                     setAddress("")
                     setCountry("")
                     setGender("")
                     setUniversity("")
                     setPursuingYear("")
                     setNationality("")
                     setProgram("")
                     setDate("")
                     setEmergencyname("")
                     setEmergencyphone("")
                     setEmergencyemail("")
                     setEmergencyaddress("")
                     setSelectedValue([])
                     setImage1("")
                     setImage2("")
                     setImage3("")
                     //setImage4("")
                     setImagePreviewUrl1(null)
                     setImagePreviewUrl2(null)
                     setImagePreviewUrl3(null)
                     //setImagePreviewUrl4(null)
                     //setCheckbox(false)
                     setIsOpened(false)
                     setSubmitted(false)
                     setPopup(true)
                     //alert(`Thank you ${firstname} for submitting the form.`)
                     //toast.success("Successfully Submitted!");
                     //toast("Successfully Submitted!")
                }
                else{
                  alert("Email Already Exists !!");
                }
            })
          }
    }

    const back_button=(e)=>{
      setIsOpened(false)
    }

  return {
    fileHandler1,
    fileHandler2,
    fileHandler3,
    //fileHandler4,
    imagePreviewUrl1,
    imagePreviewUrl2,
    imagePreviewUrl3,
    //imagePreviewUrl4,
    handleSelectChange,
    selectedValue,
    handleChange,
    handleDate,
    firstname,
    lastname,
    email,
    gender,
    date,
    address,
    country,
    nationality,
    university,
    pursuingYear,
    program,
    emergencyname,
    emergencyphone,
    emergencyemail,
    emergencyaddress,
    img1,img2,img3,
    validFile,limitFile,
    handleSubmit,
    formsubmit,
    getCourses,
    course,
    isOpened,
    back_button,
    checkboxhandler,
    submitted,
    list,
    //list1,
    checkbox,
    formsubmitted,
    boxsubmit,
   validemail,
   emailValid,
   pdf,
   popup, popupClose
  }
}

export default HomeHook

